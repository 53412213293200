import {
    FETCH_FOUNDER_CATEGORY_REQUESTING,
    FETCH_FOUNDER_CATEGORY_SUCCESS,
    FETCH_FOUNDER_CATEGORY_FAILURE,
    FETCH_FOUNDER_ARTICLES_REQUESTING,
    FETCH_FOUNDER_ARTICLES_SUCCESS,
    FETCH_FOUNDER_ARTICLES_FAILURE,
    FETCH_FOUNDER_LIST_REQUESTING,
    FETCH_FOUNDER_LIST_SUCCESS,
    FETCH_FOUNDER_LIST_FAILURE,
    GET_FOUNDER_DETAILS_REQUESTING,
    GET_FOUNDER_DETAILS_SUCCESS,
    GET_FOUNDER_DETAILS_FAILURE
} from "../constants/ActionTypes";

const initialState = {
    founderList: [],
    founderDetails: {},
    founderArticles: [],
    getFounderDetailsRequesting: false,
    getFounderDetailsSuccess: false,
    getFounderDetailsFailure: false,
    fetchFounderArticlesRequesting: false,
    fetchFounderArticlesSuccess: false,
    fetchFounderArticlesFailure: false,
    fetchAllFounderRequesting: false,
    fetchAllFounderSuccess: false,
    fetchALlFounderFailure: false,
    getFounderArticlesRequesting: false,
    getFounderArticlesSuccess: false,
    getFounderArticlesFailure: false
};

export default function FounderReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FOUNDER_DETAILS_REQUESTING:
            return {
                ...state,
                getFounderDetailsRequesting: true,
                getFounderDetailsSuccess: false,
                getFounderDetailsFailure: false,
            };
        case GET_FOUNDER_DETAILS_SUCCESS:
            return {
                ...state,
                founderDetails: action.data,
                getFounderDetailsRequesting: false,
                getFounderDetailsSuccess: false,
                getFounderDetailsFailure: false,
            };
        case GET_FOUNDER_DETAILS_FAILURE:
            return {
                ...state,
                founderDetails: {},
                getFounderDetailsRequesting: false,
                getFounderDetailsSuccess: false,
                getFounderDetailsFailure: true,
            };
        case FETCH_FOUNDER_ARTICLES_REQUESTING:
            return {
                ...state,
                fetchFounderArticlesRequesting: true,
                fetchFounderArticlesSuccess: false,
                fetchFounderArticlesFailure: false
            }
        case FETCH_FOUNDER_ARTICLES_SUCCESS:
            return {
                ...state,
                founderArticles: action.data,
                fetchFounderArticlesRequesting: false,
                fetchFounderArticlesSuccess: true,
                fetchFounderArticlesFailure: false
            }
        case FETCH_FOUNDER_ARTICLES_FAILURE:
            return {
                ...state,
                fetchFounderArticlesRequesting: false,
                fetchFounderArticlesSuccess: false,
                fetchFounderArticlesFailure: true
            }
        case FETCH_FOUNDER_LIST_REQUESTING:
            return {
                ...state,
                fetchAllFounderRequesting: true,
                fetchAllFounderSuccess: false,
                fetchALlFounderFailure: false
            }
        case FETCH_FOUNDER_LIST_SUCCESS:
            return {
                ...state,
                fetchAllFounderRequesting: false,
                fetchAllFounderSuccess: true,
                fetchALlFounderFailure: false,
                founderList: action.data
            }
        case FETCH_FOUNDER_LIST_FAILURE:
            return {
                ...state,
                fetchAllFounderRequesting: false,
                fetchAllFounderSuccess: false,
                fetchALlFounderFailure: true
            }
        default:
            return state;
    }
}
