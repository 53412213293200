import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../css/Header.css";
import logo from "../../logo/FC-large.png";
import { Button } from "@material-ui/core";

function HeaderMini() {
  return (
    <div>
      <div className="header__madebyDrag">
        <div>
          <a
            className="header__buttonTop"
            href="https://dragapp.com"
            target="_blank"
          >
            ❤️ Founder Content is <strong>proudly managed</strong> by the team @
            DragApp.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default HeaderMini;
