import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import "../../css/SignupContainer.css";
import founderfeed from "../../img/new-template-home.png";
import logo from "../../logo/FC-large.png";
import { Button } from "@material-ui/core";
import ActiveCampaign from "./ActiveCampaign";
import Grid from "@material-ui/core/Grid";

function SignupContainer() {
  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = 'https://dragapp.activehosted.com/f/embed.php?id=29';
    // script.async = true;
    // document.body.appendChild(script);
  }, []);

  return (
    <div className="signupContainer">
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="signupContainer__left">
            <img className="header__logo" src={logo} />

            <h1>Where Founders give a s#*t about other Founders.</h1>
            <p>
              Straight up – we are building the{" "}
              <strong>World’s biggest curated list</strong> of resources from
              world-class Founders, for soon-to-be world-class Founders. It’s
              everything from <strong>Pitch Decks</strong> to{" "}
              <strong>Product-Market Fit</strong> frameworks.
              <br />
              <br />
            </p>
            <p>We add new resources and deliver them straight to your inbox.</p>
            <ActiveCampaign isFormSubmit={true} />
            <div>
              <Link className="header__link" to="/all">
                <Button className="button__primary">
                  Let me view articles first >
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="signupContainer__right">
            <img className="signupContainer__image" src={founderfeed} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignupContainer;
