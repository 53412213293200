export const FETCH_RESOURCES_LIST_REQUESTING = "FETCH_RESOURCES_LIST_REQUESTING";
export const FETCH_RESOURCES_LIST_SUCCESS = "FETCH_RESOURCES_LIST_SUCCESS";
export const FETCH_RESOURCES_LIST_FAILURE = "FETCH_RESOURCES_LIST_FAILURE";

export const GET_RESOURCE_DETAILS_REQUESTING = "GET_RESOURCE_DETAILS_REQUESTING"
export const GET_RESOURCE_DETAILS_SUCCESS = "GET_RESOURCE_DETAILS_SUCCESS"
export const GET_RESOURCE_DETAILS_FAILURE = "GET_RESOURCE_DETAILS_FAILURE"


export const FETCH_RESOURCE_CATEGORY_DETAILS_REQUESTING = "FETCH_RESOURCE_CATEGORY_DETAILS_REQUESTING";
export const FETCH_RESOURCE_CATEGORY_DETAILS_SUCCESS = "FETCH_RESOURCE_CATEGORY_DETAILS_SUCCESS";
export const FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE = "FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE";

export const FETCH_RESOURCE_CATEGORY_REQUESTING = "FETCH_RESOURCE_CATEGORY_REQUESTING";
export const FETCH_RESOURCE_CATEGORY_SUCCESS = "FETCH_RESOURCE_CATEGORY_SUCCESS";
export const FETCH_RESOURCE_CATEGORY_FAILURE = "FETCH_RESOURCE_CATEGORY_FAILURE";

export const UPDATE_RESOURCE_VIEW_COUNT_SUCCESS = "UPDATE_RESOURCE_VIEW_COUNT_SUCCESS";
export const UPDATE_RESOURCE_VIEW_COUNT_FAILURE = "UPDATE_RESOURCE_VIEW_COUNT_FAILURE";
export const UPDATE_RESOURCE_VIEW_COUNT_REQUESTING = "UPDATE_RESOURCE_VIEW_COUNT_REQUESTING";


/*-----FOUNDER----*/

export const FETCH_FOUNDER_CATEGORY_REQUESTING = "FETCH_FOUNDER_CATEGORY_REQUESTING";
export const FETCH_FOUNDER_CATEGORY_SUCCESS = "FETCH_FOUNDER_CATEGORY_SUCCESS";
export const FETCH_FOUNDER_CATEGORY_FAILURE = "FETCH_FOUNDER_CATEGORY_FAILURE";


export const GET_FOUNDER_DETAILS_REQUESTING = "GET_FOUNDER_DETAILS_REQUESTING";
export const GET_FOUNDER_DETAILS_SUCCESS = "GET_FOUNDER_DETAILS_SUCCESS";
export const GET_FOUNDER_DETAILS_FAILURE = "GET_FOUNDER_DETAILS_FAILURE";


export const FETCH_FOUNDER_ARTICLES_REQUESTING = "FETCH_FOUNDER_ARTICLES_REQUESTING";
export const FETCH_FOUNDER_ARTICLES_SUCCESS = "FETCH_FOUNDER_ARTICLES_SUCCESS";
export const FETCH_FOUNDER_ARTICLES_FAILURE = "FETCH_FOUNDER_ARTICLES_FAILURE";


export const GET_FOUNDER_CATEGORY_DETAILS_REQUESTING = "GET_FOUNDER_CATEGORY_DETAILS_REQUESTING";
export const GET_FOUNDER_CATEGORY_DETAILS_SUCCESS = "GET_FOUNDER_CATEGORY_DETAILS_SUCCESS";
export const GET_FOUNDER_CATEGORY_DETAILS_FAILURE = "GET_FOUNDER_CATEGORY_DETAILS_FAILURE";


export const FETCH_ALL_FOUNDERS_REQUESTING = "FETCH_ALL_FOUNDERS_REQUESTING";
export const FETCH_ALL_FOUNDERS_SUCCESS = "FETCH_ALL_FOUNDERS_SUCCESS";
export const FETCH_ALL_FOUNDERS_FAILURE = "FETCH_ALL_FOUNDERS_FAILURE";


export const FETCH_FOUNDER_LIST_REQUESTING = "FETCH_FOUNDER_LIST_REQUESTING";
export const FETCH_FOUNDER_LIST_SUCCESS = "FETCH_FOUNDER_LIST_SUCCESS";
export const FETCH_FOUNDER_LIST_FAILURE = "FETCH_FOUNDER_LIST_FAILURE";

export const CLEAR_RESOURCE_DATA = "CLEAR_RESOURCE_DATA";
export const CLEAR_RESOURCE_DETAILS = "CLEAR_RESOURCE_DETAILS";

export const FETCH_PAGINATED_LIST_REQUESTING = "FETCH_PAGINATED_LIST_REQUESTING";