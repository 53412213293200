import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../css/SidebarOption.css";

function FounderSideBarOption({ categoryName, emoji, id, path }) {
  const history = useHistory();

  const selectCategory = () => {
    history.push({ pathname: `/founders/${path}` });
  };

  return (
    <div className="sidebarOption" onClick={selectCategory}>
      <span>{emoji}</span>
      {categoryName}
    </div>
  );
}

export default FounderSideBarOption;
