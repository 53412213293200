import React from "react";
import "../../css/FeedOption.css";
import "../../css/FounderFeedOption.css";
import _ from "lodash";
import example from "../../img/example.png";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

function FounderFeedOption({
  firstname,
  lastname,
  jobrole,
  company,
  founderimage,
  foundercategoryid,
  id,
  founderCategoryName
  //   title,
  //   categoryId,
  //   itemId,
  //   tileimage,
  //   contenttype,
  //   yearpublished
}) {

  return (
    <div className="founderFeedoption__container">
      <Link className="feedOption__link" to={{ pathname: `${founderCategoryName ?.toLocaleLowerCase()}/${firstname ?.toLocaleLowerCase()}-${lastname ?.toLocaleLowerCase()}`, state: { id, foundercategoryid } }}>
        <div className="feedOption__image">
          <img
            className="header__logo"
            src={`/images/founderimages/${founderimage}`}
          />
        </div>

        <div className="founderFeedOption__resourceTitle">
          {_.capitalize(firstname)} {_.capitalize(lastname)}
        </div>
        <div className="feedOption__resourceExcerpt">
          {jobrole} @ {company}
        </div>
      </Link>
    </div >
  );
}

export default FounderFeedOption;
