import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../../css/ActiveCampaign.css";

function ActiveCampaign(props) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { isFormSubmit } = props;

  const onSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("https://dragapp.activehosted.com/proc.php", {
      method: "POST",
      body: data,
      mode: "no-cors"
    })
      .then(response => {
        setFormSubmitted(true);
      })
      .catch(error => console.log("Request failed", error));
  };

  if (formSubmitted) {
    return <Redirect to="/all" />;
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input type="hidden" name="u" value="29" />
        <input type="hidden" name="f" value="29" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <div class="_form-content">
          <div className="_form_element _x11026056 _inline-style signupContainer__signup">
            <div className="signupContainer__input">
              <input
                type="text"
                name="email"
                placeholder="Your email address"
                required
              />
            </div>
            <div class="_button-wrapper _inline-style">
              <Button className="button__ac" type="submit">
                Join free
              </Button>
              {/* <button id="_form_29_submit" class="_submit" type="submit">
                        Join free
                  </button> */}
            </div>
          </div>
          <div class="_clear-element"></div>
        </div>
        <div class="_form-thank-you" style={{ display: "none" }}></div>
      </form>
    </div>
  );
}

export default ActiveCampaign;
