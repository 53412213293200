import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import FounderSidebar from "../main/FounderSidebar";
import FounderFeed from "../main/FounderFeed";
import Header from "./Header";
import Footer from "./Footer";
import _ from "lodash";
import "../../css/HomeContainer.css";
import Hidden from "@material-ui/core/Hidden";
import { useSelector, useDispatch } from "react-redux";
import { getFounderCategoryDetails } from "../../../store/actions/CategoryAction";
import { fetchAllFounder, searchFounder, filterFounderList, fetchFounderByCategoryId } from "../../../store/actions/FounderAction";

function HomeFounderContainer(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { founderList, founderCategory } = useSelector(state => state.founderReducer);
  const { categoryDetails } = useSelector(state => state.categoryReducer);

  const handleOnChange = event => {
    setSearchText(event.target.value);
  };

  const handleFilter = async (filterObj) => {
    if (_.isEmpty(filterObj)) {
      dispatch(fetchAllFounder())
    } else {
      dispatch(filterFounderList({ filterObj }))
    }

  }

  useEffect(async () => {
    if (categoryDetails && categoryDetails.path && categoryDetails.path != "all") {
      dispatch(fetchFounderByCategoryId({ id: categoryDetails.id }));
    }
  }, [categoryDetails]);

  useEffect(async () => {
    if (id && !searchText) {
      if (id == "all") {
        dispatch(fetchAllFounder())
      }
    }
    dispatch(getFounderCategoryDetails({ path: id }))
  }, [id]);

  useEffect(async () => {
    if (searchText.length) {
      dispatch(searchFounder({ searchText }))
    }
  }, [searchText]);

  return (
    <Fragment>
      <Header />
      <div className="homeContainer">
        <Hidden smDown>
          <FounderSidebar
            searchText={searchText}
            handleOnChange={handleOnChange}
          />
        </Hidden>

        <FounderFeed
          handleFilter={handleFilter}
          founderList={founderList}
          founderCategoryDetails={categoryDetails}
        />
      </div>
      <Footer />
    </Fragment>
  );
}

export default HomeFounderContainer;
