import db from "../firebase";
import { axiosRequestHandler } from "../helper/axios";

export const FetchResourceCategoriesAPI = async data => {
    try {
        return await axiosRequestHandler(`category/resource`, "get");
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const FetchResourceCategoryDetailsAPI = async data => {
    try {
        return await axiosRequestHandler(`category/resource-details?path=${data.path}`, "get");
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const FetchFounderCategoriesAPI = async data => {
    try {
        return await axiosRequestHandler(`category/founder`, "get");
    } catch (error) {
        throw new Error({ error: true, mess: "" })
    }
}

export const GetFounderCategoryDetailsAPI = async data => {
    try {
        return await axiosRequestHandler(`category/details-founder?path=${data.path}`, "get");
    } catch (error) {
        throw new Error({ error: true, mess: "" })
    }
}