import { combineReducers } from "redux";
import resourceReducer from "./ResourceReducer";
import founderReducer from "./FounderReducer";
import categoryReducer from "./CategoryReducer";

const rootReducer = combineReducers({
    resourceReducer,
    founderReducer,
    categoryReducer
});

export default rootReducer;
