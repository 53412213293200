import React, { useEffect, useState } from "react";
import "../../css/FeedOption.css";
import example from "../../img/example.png";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import db from "../../../firebase";

function FeedOption({
  urlTitle,
  title,
  categoryId,
  itemId,
  tileimage,
  contenttype,
  yearpublished,
  categoryName
}) {

  const [name, setName] = useState(categoryName);


  useEffect(() => {
    setName(categoryName)
  }, [categoryName])


  return (
    <div className="feedoption__container">
      <Link className="feedOption__link" to={{ pathname: `/${name ?.toLocaleLowerCase().replace(' ', '-')}/${urlTitle}`, state: { id: itemId } }}>
        <div className="feedOption__image">
          <img
            className="header__logo"
            src={`/images/tileimages/${tileimage}`}
          />
        </div>

        <div className="feedOption__resourceTitle">{title}</div>
        <div className="feedOption__resourceExcerpt">
          💾 {contenttype} <span>| </span>📅 {yearpublished}
        </div>
      </Link>
    </div>
  );
}

export default FeedOption;
