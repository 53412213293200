
import db from "../firebase";
import { isEmpty } from "lodash";
import { axiosRequestHandler } from "../helper/axios";

export const FetchAllFoundersAPI = async data => {
    try {
        return await axiosRequestHandler(`founders`, "get");
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const FetchFounderByCategoryIdAPI = async data => {
    try {
        return await axiosRequestHandler(`founders/geByCategory`, "post", { categoryId: data.id });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const GetDetailsOfFounderAPI = async data => {
    try {
        return await axiosRequestHandler(`founders/details?id=${data.founderId}`, "get");
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const GetFounderByNameAPI = async data => {
    try {
        return await axiosRequestHandler(`founders/getByName`, "post", { firstName: data.firstName, lastName: data.lastName });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const SearchFounderAPI = async data => {
    try {
        return await axiosRequestHandler(`founders/search`, "post", { searchText: data.searchText });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const FilterFounderAPI = async data => {
    try {
        return await axiosRequestHandler(`founders/filter`, "post", { filterObj: data.filterObj });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

