import React, { useEffect, useState, Fragment } from "react";
import "../../css/ResourceContainer.css";
import _ from "lodash";
import Header from "./Header";
import Fotter from "./Footer";
import FeedOption from "./FeedOption";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import LaunchIcon from "@material-ui/icons/Launch";
import { Helmet } from "react-helmet-async";
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from "react-html-parser";
import { useSelector, useDispatch } from "react-redux";
import {
  getResourceDetails,
  getResourceByFounder,
  getResourceBySearchTitle,
  updateResourceViewCount,
} from "../../../store/actions/ResourceAction";
import { getFounderDetails } from "../../../store/actions/FounderAction";
import { CLEAR_RESOURCE_DETAILS } from "../../../store/constants/ActionTypes"

function ResourceContainer(props) {
  const dispatch = useDispatch();
  const itemId =
    props.location && props.location.state && props.location.state.id;
  const searchTitle = props ?.match ?.params ?.itemId;

  const { resourceDetails, resourceList } = useSelector(state => state.resourceReducer);
  const { founderDetails } = useSelector((state) => state.founderReducer);

  useEffect(() => {
    if (itemId) {
      dispatch(updateResourceViewCount({ resourceId: itemId }));
      dispatch(getResourceDetails({ resourceId: itemId }))
    }
  }, [itemId]);

  useEffect(() => {
    if (!itemId) {
      dispatch(getResourceBySearchTitle({ searchTitle: searchTitle }));
    }
  }, [itemId]);

  useEffect(() => {
    if (!_.isEmpty(resourceDetails)) {
      dispatch(getFounderDetails({ founderId: resourceDetails.founderid }))
      //GetDetailsOfFounderAPI() & GetFounderCategory()
      dispatch(getResourceByFounder({ founderId: resourceDetails.founderid }))
    }
  }, [resourceDetails]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_RESOURCE_DETAILS })
    }
  }, [])

  return (
    <Fragment>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{resourceDetails ?.resourcesmetatitle}</title>
        <meta
          name="description"
          content={resourceDetails ?.resourcesmetacontent}
        />
      </Helmet>
      <div className="resourceContainer">
        <div className="resourceContainer__top">
          <Grid container>
            <Grid item sm={12} md={6}>
              <div className="resourceContainer__left">
                <h1>{resourceDetails ?.title}</h1>
                <h3>{resourceDetails ?.subtitle}</h3>
                <p>
                  {resourceDetails && resourceDetails.viewCount
                    ? `Viewed ${resourceDetails.viewCount} times`
                    : ""}
                </p>
                <div className="resourceContainer__resource">
                  {ReactHtmlParser(
                    resourceDetails ?.freatureimage ?.replace("watch?v=", "embed/")
                  )}
                </div>
                {/* <div className="resourceContainer__filters">
              <Button variant="outlined">SaaS</Button>

              <Button variant="outlined">VC backed</Button>

              <Button variant="outlined">🇺🇸 San Francisco</Button>
            </div> */}
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div className="resourceContainer__right">
                <Link
                  className="resourceContainer__founderPath"
                  to={{
                    pathname: `/founders/${founderDetails ?.categoryName ?.toLocaleLowerCase()}/${founderDetails ?.firstname ?.toLocaleLowerCase()}-${founderDetails ?.lastname ?.toLocaleLowerCase()}`,
                    state: { id: resourceDetails ?.founder }
                  }}
                >
                  <div className="resourceContainer__founder">
                    <div className="resourceContainer__image">
                      <img
                        className="founderContainer__avatar"
                        src={`/images/founderimages/${founderDetails ?.founderimage}`}
                      />
                    </div>
                    <div className="founderContainer__founderDetails">
                      <div className="founderContainer__nameProfile">
                        <h2>
                          {founderDetails &&
                            _.capitalize(founderDetails ?.firstname)}{" "}
                          {founderDetails &&
                            _.capitalize(founderDetails ?.lastname)}{" "}
                          <LaunchIcon className="founderContainer__launch" />
                        </h2>
                      </div>
                      <div>
                        {founderDetails ?.jobrole} @ {founderDetails ?.company}
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="resourceContainer__profiles">
                  <div>
                    <strong>🔖 Company</strong>
                  </div>
                  <div>{founderDetails ?.company}</div>
                </div>
                <div className="resourceContainer__profiles">
                  <div>
                    <strong>💾 Content Type</strong>
                  </div>
                  <div>{resourceDetails ?.contenttype}</div>
                </div>

                <div className="resourceContainer__profiles">
                  <div>
                    <strong>📅 Year published</strong>
                  </div>
                  <div>{resourceDetails ?.year}</div>
                </div>

                <div className="resourceContainer__buttons">
                  <div className="founderContainer">
                    <a href={resourceDetails ?.originalsource} target="_blank">
                      <Button className="resourceContainer__follow">
                        See original post
                      </Button>
                    </a>
                  </div>
                  <div className="founderContainer">
                    <a
                      href={
                        "https://twitter.com/intent/tweet?url=&text=Check%20out%20this%20article%20on%20" +
                          resourceDetails ?.title +
                            "%20from%20" +
                            "@" +
                            founderDetails ?.foundertwitter +
                            // AJ.DOURL +
                            ". %20" +
                            `${window.location.href}`
                      }
                      target="_blank"
                    >
                      <Button className="resourceContainer__share">
                        <TwitterIcon /> Tweet this Content
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="resourceContainer__relatedContent">
          <h2>
            {founderDetails && _.capitalize(founderDetails ?.firstname)}'s
            Founder Content
          </h2>

          <div className="founderContainer__content">
            {resourceList.map(item => (
              <Grid item xs={12} sm={6} lg={4}>
                <FeedOption
                  urlTitle={item.urlTitle}
                  itemId={item.id}
                  title={item.title}
                  categoryName={item.categoryname}
                  categoryId={item.categoryid}
                  tileimage={item.tileimage}
                  contenttype={item.contenttype}
                  yearpublished={item.yearpublished}
                />
              </Grid>
            ))}
          </div>
        </div>
      </div>
      <Fotter />
    </Fragment>
  );
}

export default ResourceContainer;
