import {
    FETCH_RESOURCE_CATEGORY_REQUESTING,
    FETCH_RESOURCE_CATEGORY_SUCCESS,
    FETCH_RESOURCE_CATEGORY_FAILURE,
    FETCH_RESOURCE_CATEGORY_DETAILS_REQUESTING,
    FETCH_RESOURCE_CATEGORY_DETAILS_SUCCESS,
    FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE,
    FETCH_FOUNDER_CATEGORY_REQUESTING,
    FETCH_FOUNDER_CATEGORY_SUCCESS,
    FETCH_FOUNDER_CATEGORY_FAILURE,
    GET_FOUNDER_CATEGORY_DETAILS_REQUESTING,
    GET_FOUNDER_CATEGORY_DETAILS_SUCCESS,
    GET_FOUNDER_CATEGORY_DETAILS_FAILURE
} from "../constants/ActionTypes";

import { FetchResourceCategoryDetailsAPI, FetchFounderCategoriesAPI, FetchResourceCategoriesAPI, GetFounderCategoryDetailsAPI } from "../../services/CategoryService";

export const fetchResourceCategories = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_RESOURCE_CATEGORY_REQUESTING });
        let apiResponse = await FetchResourceCategoriesAPI(requestParameter);
        if (!apiResponse) {
            dispatch({
                type: FETCH_RESOURCE_CATEGORY_FAILURE,
                message: "Incorrect Details",
                errors: "Something went wrong..!!"
            });
            return;
        }
        if (apiResponse && apiResponse.response) {
            const data = apiResponse.response.map(doc => formatCategoryResponse(doc))
            dispatch({
                type: FETCH_RESOURCE_CATEGORY_SUCCESS,
                data: data,
            });
        } else {
            dispatch({
                type: FETCH_RESOURCE_CATEGORY_FAILURE,
                errors: apiResponse.error
            });
        }
    } catch (error) {
        dispatch({ type: FETCH_RESOURCE_CATEGORY_FAILURE });
    }
};

export const fetchResourceCategoryDetails = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_RESOURCE_CATEGORY_DETAILS_REQUESTING });
        let apiResponse = await FetchResourceCategoryDetailsAPI(requestParameter);
        if (!apiResponse) {
            dispatch({
                type: FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE,
                message: "Incorrect Details",
                errors: "Something went wrong..!!"
            });
            return;
        }
        if (apiResponse) {
            const data = formatCategoryResponse(apiResponse.response)
            dispatch({
                type: FETCH_RESOURCE_CATEGORY_DETAILS_SUCCESS,
                data: data,
            });
        } else {
            dispatch({
                type: FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE,
                errors: apiResponse.error
            });
        }
    } catch (error) {
        dispatch({ type: FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE });
    }
};


export const fetchFounderCategories = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_FOUNDER_CATEGORY_REQUESTING });
        let apiResponse = await FetchFounderCategoriesAPI(requestParameter);
        if (!apiResponse) {
            dispatch({
                type: FETCH_FOUNDER_CATEGORY_FAILURE,
                message: "Incorrect Details",
                errors: "Something went wrong..!!"
            });
            return;
        }
        if (apiResponse && apiResponse.response) {
            const data = apiResponse.response.map(doc => formatCategoryResponse(doc))
            dispatch({
                type: FETCH_FOUNDER_CATEGORY_SUCCESS,
                data: data,
                message: "Areas fetched successfully"
            });
        } else {
            dispatch({
                type: FETCH_FOUNDER_CATEGORY_FAILURE,
                errors: apiResponse.error
            });
        }
    } catch (error) {
        dispatch({ type: FETCH_FOUNDER_CATEGORY_FAILURE });
    }
};

export const getFounderCategoryDetails = requestParameter => async dispatch => {
    try {
        dispatch({ type: GET_FOUNDER_CATEGORY_DETAILS_REQUESTING });
        let apiResponse = await GetFounderCategoryDetailsAPI(requestParameter);
        if (!apiResponse) {
            dispatch({
                type: GET_FOUNDER_CATEGORY_DETAILS_FAILURE,
                message: "Incorrect Details",
                errors: "Something went wrong..!!"
            });
            return;
        }
        if (apiResponse && apiResponse) {
            const data = formatCategoryResponse(apiResponse.response)
            dispatch({
                type: GET_FOUNDER_CATEGORY_DETAILS_SUCCESS,
                data: data,
            });
        } else {
            dispatch({ type: GET_FOUNDER_CATEGORY_DETAILS_FAILURE });
        }
    } catch (error) {
        dispatch({ type: GET_FOUNDER_CATEGORY_DETAILS_FAILURE });
    }
};


const formatCategoryResponse = (categoryResponse) => {
    return {
        id: categoryResponse.id,
        name: categoryResponse.name,
        emoji: categoryResponse.emoji,
        path: categoryResponse.path
    }
}