import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCFBwyk6tPmSozxPfaGXoqHxEVI4uszwpQ",
  authDomain: "founder-content.firebaseapp.com",
  projectId: "founder-content",
  storageBucket: "founder-content.appspot.com",
  messagingSenderId: "557939695774",
  appId: "1:557939695774:web:43b920f309a0eeb1b9db8f",
  measurementId: "G-881X8H6ZD7"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
const db = firebase.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

db.enablePersistence().catch(function(err) {
  if (err.code == "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

// db.disableNetwork();

export { auth, provider };
export default db;
