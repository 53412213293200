import React, { useEffect, useState, Fragment } from "react";
import "../../css/FounderContainer.css";
import _ from "lodash";
import db from "../../../firebase";
import Header from "./Header";
import Footer from "./Footer";
import FeedOption from "./FeedOption";
import Grid from "@material-ui/core/Grid";
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from "react-redux";
import { fetchFounderArticles } from "../../../store/actions/ResourceAction"
import { getFounderDetails, getFounderByName } from "../../../store/actions/FounderAction";

function FounderContainer(props) {
  let id;
  if (props.location) {
    id = props.location && props.location.state && props.location.state.id;
  }
  const dispatch = useDispatch();
  const founderName = props ?.match ?.params ?.id;
  const [founderId, setFounderId] = useState(id);
  const { founderDetails, founderArticles } = useSelector((state) => state.founderReducer);

  useEffect(() => {
    if (id) {
      setFounderId(id)
    }
  }, [id])

  useEffect(async () => {
    if (!founderId) {
      const firstName = founderName.split("-")[0];
      const lastName = founderName.split("-")[1];
      dispatch(getFounderByName({ firstName, lastName }))
    }
  }, [founderName]);

  useEffect(() => {
    if (!_.isEmpty(founderDetails)) {
      dispatch(fetchFounderArticles({ founderId: founderDetails.id }))
    }
  }, [founderDetails])


  useEffect(async () => {
    if (founderId) {
      dispatch(getFounderDetails({ founderId }))
    }
  }, [founderId]);

  return (
    <Fragment>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{founderDetails ?.foundermetatitle}</title>
        <meta name="description" content={founderDetails ?.foundermetacontent} />
      </Helmet>
      <Grid container className="founderContainer" style={{ paddingLeft: 25 }}>
        <Grid item container xs={false} sm={5} md={3} direction="column">
          <Grid>
            <div className="founderContainer__image">
              <img
                className="founderContainer__avatar"
                src={`/images/founderimages/${founderDetails ?.founderimage}`}
              />
            </div>
          </Grid>
          {
            founderDetails && founderDetails.founderwebsite &&
            <Grid>
              <div className="founderContainer__profiles">
                <div>
                  <strong>💻 Personal website</strong>
                </div>
                <div>{founderDetails.founderwebsite}</div>
              </div>
            </Grid>
          }
          <Grid>
            <div className="founderContainer__profiles">
              <div>
                <strong>🧑🏽 Social</strong>
              </div>
              {founderDetails && founderDetails.foundertwitter && <a href={`https://twitter.com/${founderDetails.foundertwitter}/`} target="_blank">Twitter</a>}
              {founderDetails && founderDetails.founderlinkedin && <a href={founderDetails.founderlinkedin} target="_blank">LinkedIn</a>}
              {founderDetails && founderDetails.founderangellist && <a href={founderDetails.founderangellist} target="_blank">AngelList</a>}
            </div>
          </Grid>
          {founderDetails && founderDetails.founderblog &&
            <Grid>
              <div className="founderContainer__profiles">
                <div> <strong>✍🏼 Blogs</strong> </div>
                <a href={founderDetails.founderblog} target="_blank"> Blog</a>
              </div>
            </Grid>
          }
          {founderDetails && founderDetails.founderpodcast &&
            <Grid>
              <div className="founderContainer__profiles">
                <div>
                  <strong>🎙️ Podcasts</strong>
                </div>
                <a href={founderDetails.founderpodcast} target="_blank">
                  Podcast
              </a>
              </div>
            </Grid>
          }
          {founderDetails && founderDetails.foundervideos &&
            <Grid>
              <div className="founderContainer__profiles">
                <div>
                  <strong>🎥 Videos</strong>
                </div>
                <a href={founderDetails.foundervideos} target="_blank">
                  Video
              </a>
              </div>
            </Grid>
          }
          <Grid>
            <div className="founderContainer__profiles">
              <div>
                <strong>📎 Other</strong>
              </div>
              {founderDetails && founderDetails.foundercrunchbase && <a href={founderDetails ?.foundercrunchbase} target="_blank">Crunchbase</a>}
              {founderDetails && founderDetails.foundersubstack && <a href={founderDetails ?.foundersubstack} target="_blank">Substack</a>}
              {founderDetails && founderDetails.foundergithub && <a href={founderDetails ?.foundergithub} target="_blank">Github</a>}
            </div>
          </Grid>

        </Grid>
        <Grid
          item
          container
          spacing={2}
          xs={12}
          sm={7}
          md={9}
          direction="column"
        >
          <Grid item className="founderContent__topSection">
            <div>
              <h1>
                {founderDetails && _.capitalize(founderDetails.firstname)}{" "}
                {founderDetails && _.capitalize(founderDetails.lastname)}
              </h1>
              <h3>
                {founderDetails && _.capitalize(founderDetails.firstname)} is{" "}
                {founderDetails ?.jobrole} @ {founderDetails ?.company}.
              </h3>
              <p>
                {founderArticles.length != 0 && `${founderArticles.length}`}{" "}
                resources from{" "}
                {founderDetails && _.capitalize(founderDetails.firstname)}
              </p>
              {/* <div className="founderContainer__filters">
                <Button variant="outlined">SaaS</Button>

                <Button variant="outlined">VC backed</Button>

                <Button variant="outlined">🇺🇸 San Francisco</Button>
              </div> */}

              <div className="founderContainer__companies">
                <div>
                  <strong>Companies founded: </strong>
                </div>
                <div>
                  {founderDetails ?.company}

                  {/* <img className="founderContainer__companyLogo" src={segment} /> */}
                </div>
                <div></div>
              </div>
            </div>
            {/* <div>
            <Button className="founderContent__follow">
              Follow {founderDetails?.firstname}'s Content
            </Button>
          </div> */}
          </Grid>
          <Grid item>
            {" "}
            <h2>
              {founderDetails && _.capitalize(founderDetails.firstname)}'s Founder
              Content
            </h2>
          </Grid>
          <Grid item>
            <div className="founderContainer__content">
              {founderArticles && founderArticles.map(item => (
                <Grid item xs={12} sm={6} lg={4}>
                  <FeedOption
                    urlTitle={item.urlTitle}
                    itemId={item.id}
                    title={item.title}
                    tileimage={item.tileimage}
                    categoryName={item.categoryname}
                    categoryId={item.categoryId}
                    contenttype={item.contenttype}
                    yearpublished={item.yearpublished}
                  />
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Fragment >
  );
}

export default FounderContainer;
