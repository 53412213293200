import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./assets/css/common.css";
import Header from "../src/assets/components/main/Header";
import HeaderMini from "../src/assets/components/main/HeaderMini";
import { HelmetProvider } from 'react-helmet-async';
import ResourceContainer from "../src/assets/components/main/ResourceContainer";
import FounderContainer from "../src/assets/components/main/FounderContainer";
import HotContainer from "../src/assets/components/main/HotContainer";
import SubmitContainer from "../src/assets/components/main/SubmitContainer";
import JoinContainer from "../src/assets/components/main/JoinContainer";
import ContactContainer from "../src/assets/components/main/ContactContainer";
import WhyContainer from "../src/assets/components/main/WhyContainer";
import TermsContainer from "../src/assets/components/main/TermsContainer";
import PrivacyContainer from "../src/assets/components/main/PrivacyContainer";
import SignupContainer from "../src/assets/components/main/SignupContainer";

import HomeContainer from "../src/assets/components/main/HomeContainer";
import HomeFounderContainer from "../src/assets/components/main/HomeFounderContainer";

import Footer from "../src/assets/components/main/Footer";
import FooterMini from "../src/assets/components/main/FooterMini";
import { PersistGate } from 'redux-persist/integration/react'
import store from "./store/reduxStore"
import { Provider } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

function App() {
  let recaptchaInstance = undefined;
  const [captchaSolved, setCaptchaSolved] = useState(true);

  const onChange = (response) => {
    response ? setCaptchaSolved(true) : setCaptchaSolved(false)
  }

  useEffect(() => {
    if (recaptchaInstance && !captchaSolved) {
      recaptchaInstance.execute();
    }
  }, [captchaSolved])

  return (
    <HelmetProvider>
      <Provider store={store}>
        <div className="app">
          <div className="app__body">
            <ReCAPTCHA
              ref={e => recaptchaInstance = e}
              sitekey="6LcS5TQaAAAAAL1hHXjUFC8Fo0cPMcJw78VUvvul"
              onChange={onChange}
              render="explicit"
              size="invisible"
            />
            <Router>
              <Switch>
                <Route path="/resources"></Route>
                <Route path="/hot">
                  <Header />
                  <HotContainer />
                  <Footer />
                </Route>
                <Route path="/add">
                  <Header />

                  <SubmitContainer />
                  <Footer />
                </Route>

                <Route path="/join">
                  <Header />

                  <JoinContainer />
                  <Footer />
                </Route>
                <Route path="/contact">
                  <Header />

                  <ContactContainer />
                  <Footer />
                </Route>
                <Route path="/why">
                  <Header />

                  <WhyContainer />
                  <Footer />
                </Route>
                <Route path="/terms">
                  <Header />

                  <TermsContainer />
                  <Footer />
                </Route>
                <Route path="/privacy">
                  <Header />
                  <PrivacyContainer />
                  <Footer />
                </Route>
                <Route exact path="/founders/:id" render={(props) => (
                  <HomeFounderContainer {...props} captchaSolved={captchaSolved} />
                )} />
                <Route exact path="/founders/:foundercategoryid/:id" render={(props) => (
                  <FounderContainer {...props} captchaSolved={captchaSolved} />
                )} />
                <Route exact path="/:id/:itemId" render={(props) => (
                  <ResourceContainer {...props} captchaSolved={captchaSolved} />
                )} />
                <Route exact path="/:id" render={(props) => (
                  <HomeContainer {...props} captchaSolved={captchaSolved} />
                )} />
                <Route exact path="/">
                  {/* <HeaderMini /> */}
                  <SignupContainer />
                  <FooterMini />
                </Route>
              </Switch>
            </Router>
          </div>
        </div >
      </Provider>
    </HelmetProvider>
  );
}

export default App;
