import {
    GET_FOUNDER_DETAILS_REQUESTING,
    GET_FOUNDER_DETAILS_SUCCESS,
    GET_FOUNDER_DETAILS_FAILURE,
    FETCH_FOUNDER_LIST_REQUESTING,
    FETCH_FOUNDER_LIST_SUCCESS,
    FETCH_FOUNDER_LIST_FAILURE
} from "../constants/ActionTypes";
import {
    GetDetailsOfFounderAPI,
    FetchAllFoundersAPI,
    SearchFounderAPI,
    FilterFounderAPI,
    GetFounderByNameAPI,
    FetchFounderByCategoryIdAPI,
} from "../../services/FounderService";

export const getFounderDetails = requestParameter => async dispatch => {
    try {
        dispatch({ type: GET_FOUNDER_DETAILS_REQUESTING });
        const apiResponse = await GetDetailsOfFounderAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: GET_FOUNDER_DETAILS_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = formatFounderResponse(apiResponse.response);
            dispatch({ type: GET_FOUNDER_DETAILS_SUCCESS, data });
        } else {
            dispatch({ type: GET_FOUNDER_DETAILS_FAILURE });
        }
    } catch (error) {
        dispatch({ type: GET_FOUNDER_DETAILS_FAILURE });
    }
};

export const getFounderByName = requestParameter => async dispatch => {
    try {
        dispatch({ type: GET_FOUNDER_DETAILS_REQUESTING });
        const apiResponse = await GetFounderByNameAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: GET_FOUNDER_DETAILS_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse) {
            const data = formatFounderResponse(apiResponse.response);
            dispatch({ type: GET_FOUNDER_DETAILS_SUCCESS, data });
        } else {
            dispatch({ type: GET_FOUNDER_DETAILS_FAILURE });
        }
    } catch (error) {
        dispatch({ type: GET_FOUNDER_DETAILS_FAILURE });
    }
};

export const fetchFounderByCategoryId = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_FOUNDER_LIST_REQUESTING });
        const apiResponse = await FetchFounderByCategoryIdAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_FOUNDER_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = apiResponse.response.map(founder => formatFounderResponse(founder))
            dispatch({ type: FETCH_FOUNDER_LIST_SUCCESS, data });
        } else {
            dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
    }
}

export const fetchAllFounder = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_FOUNDER_LIST_REQUESTING });
        const apiResponse = await FetchAllFoundersAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_FOUNDER_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = apiResponse.response.map(founder => formatFounderResponse(founder));
            dispatch({ type: FETCH_FOUNDER_LIST_SUCCESS, data });
        } else {
            dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
    }
}

export const searchFounder = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_FOUNDER_LIST_REQUESTING });
        const apiResponse = await SearchFounderAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_FOUNDER_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse) {
            const data = apiResponse.response.map(founder => formatFounderResponse(founder))
            dispatch({ type: FETCH_FOUNDER_LIST_SUCCESS, data });
        } else {
            dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
    }
}

export const filterFounderList = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_FOUNDER_LIST_REQUESTING });
        const apiResponse = await FilterFounderAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_FOUNDER_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = apiResponse.response.map(founder => formatFounderResponse(founder))
            dispatch({ type: FETCH_FOUNDER_LIST_SUCCESS, data });
        } else {
            dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_FOUNDER_LIST_FAILURE });
    }
}

const formatFounderResponse = (founder) => {
    return {
        id: founder.id,
        company: founder.company,
        firstname: founder.firstName,
        lastname: founder.lastName,
        foundercategoryid: founder.founderCategoryId,
        jobrole: founder.jobRole,
        founderimage: founder.founderImage,
        founderlocation: founder.founderLocation,
        founderblog: founder.founderBlog,
        founderbook: founder.founderBook,
        foundercity: founder.founderCity,
        foundercountry: founder.founderCountry,
        foundergender: founder.founderGender,
        foundergithub: founder.founderGithub,
        founderlinkedin: founder.founderLinkedin,
        founderother: founder.founderOther,
        founderpodcast: founder.founderPodcast,
        founderproducthunt: founder.founderProductHunt,
        founderquora: founder.founderQuora,
        foundersubstack: founder.founderSubstack,
        foundertwitter: founder.founderTwitter,
        foundervideos: founder.founderVideos,
        founderwikipedia: founder.founderWikipedia,
        foundercrunchbase: founder.founderCrunchBase,
        founderwebsite: founder.founderWebsite
    };
}