import db from "../firebase";
import firebase from "firebase";
import { isEmpty } from "lodash";
import { axiosRequestHandler } from "../helper/axios";

export const FetchResourceAPI = async data => {
    try {
        return await axiosRequestHandler(`resources`, "get", data);
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const FetchResourceByCategoryAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/get-by-category`, "post", { categoryId: data.categoryId });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}


export const SearchResourceAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/search`, "post", { searchString: data.searchText.toLocaleLowerCase(), pageNumber: data.pageNumber });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const FilterResourceAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/filter`, "post", { filterObj: data.filterObj });
        // let query = db.collection("resources");
        // if (!isEmpty(data.filterObj)) {
        //     data.filterObj.map(({ filterBy, filterValue }) => {
        //         query = query.where(filterBy, '==', filterValue)
        //     })
        // }
        // return await query.get({ source: 'server', includeMetadataChanges: true })
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const FetchResourceByFounderIdAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/get-by-founder`, "post", { id: data.founderId });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const GetResourceByTitleAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/search`, "post", { searchString: data.searchTitle });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const GetResourceBySearchTitleAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/get-by-search-term`, "post", { searchString: data.searchTitle });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const UpdateResourceViewCountAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/update-view-count`, "post", { id: data.resourceId });
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

export const GetResourceDetailsAPI = async data => {
    try {
        return await axiosRequestHandler(`resources/details?id=${data.resourceId}`, "get");
    } catch (error) {
        throw new Error({ error: true, message: "Something went wrong..!!" });
    }
}

