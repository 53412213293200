import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../css/Header.css";
import logo from "../../logo/FC-large.png";
import { Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";

function Header() {
  return (
    <div>
      <Hidden smDown>
        <div className="header__madebyDrag">
          <div>
            <a
              className="header__buttonTop"
              href="https://dragapp.com"
              target="_blank"
            >
              ❤️ Founder Content is <strong>proudly managed</strong> by the team
              @ DragApp.com
            </a>
          </div>
        </div>
      </Hidden>

      <div className="header">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className="header__left">
              <Link to="/">
                <img className="header__logo" src={logo} />
              </Link>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className="header__right">
              <Link className="header__link" to="/all">
                <Button className="button__primary">Resources</Button>
              </Link>
              <Link className="header__link" to="/founders/all">
                <Button className="button__primary">Founders</Button>
              </Link>
              <Hidden smDown>
                <Link className="header__link" to="/hot">
                  <Button className="button__primary">🔥 What's hot</Button>
                </Link>
                <Link className="header__link" to="/add">
                  <Button className="button__primary">Add</Button>
                </Link>
              </Hidden>
              <Link className="header__link" to="/">
                <Button className="button__main">Join free</Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Header;
