import {
    CLEAR_RESOURCE_DATA,
    CLEAR_RESOURCE_DETAILS,
    FETCH_RESOURCES_LIST_REQUESTING,
    FETCH_RESOURCES_LIST_SUCCESS,
    FETCH_RESOURCES_LIST_FAILURE,
    GET_RESOURCE_DETAILS_REQUESTING,
    GET_RESOURCE_DETAILS_SUCCESS,
    GET_RESOURCE_DETAILS_FAILURE,
    UPDATE_RESOURCE_VIEW_COUNT_SUCCESS,
    UPDATE_RESOURCE_VIEW_COUNT_FAILURE,
    UPDATE_RESOURCE_VIEW_COUNT_REQUESTING,
    FETCH_PAGINATED_LIST_REQUESTING
} from "../constants/ActionTypes";
import { cloneDeep } from "lodash";
const initialState = {
    total: 0,
    page: 0,
    lastPage: 0,
    resourceList: [],
    resourceDetails: {},
    getResourceDetailsRequesting: false,
    getResourceDetailsSuccess: false,
    getResourceDetailsFailure: false,
    fetchResourceListRequesting: false,
    fetchResourceListSuccess: false,
    fetchResourceListFailure: false,
    fetchPaginatedListRequesting: false,

};

export default function ResourceReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_RESOURCES_LIST_REQUESTING:
            return {
                ...state,
                resourceList: [],
                total: 0,
                fetchResourceListRequesting: true,
                fetchResourceListSuccess: false,
                fetchResourceListFailure: false,
            };
        case FETCH_PAGINATED_LIST_REQUESTING:
            return {
                ...state,
                fetchPaginatedListRequesting: true
            }
        case FETCH_RESOURCES_LIST_SUCCESS:
            let resourceListClone = cloneDeep(state.resourceList);
            action.data.isPagination ? resourceListClone.push(...action.data.list) : resourceListClone = action.data.list;
            return {
                ...state,
                page: action.data.page,
                total: action.data.total,
                lastPage: action.data.lastPage,
                resourceList: resourceListClone,
                fetchResourceListRequesting: false,
                fetchResourceListSuccess: true,
                fetchResourceListFailure: false,
                fetchPaginatedListRequesting: false
            };
        case FETCH_RESOURCES_LIST_FAILURE:
            return {
                ...state,
                fetchResourceListRequesting: false,
                fetchResourceListSuccess: false,
                fetchResourceListFailure: true,
            };
        case GET_RESOURCE_DETAILS_REQUESTING:
            return {
                ...state,
                getResourceDetailsRequesting: true,
                getResourceDetailsSuccess: false,
                getResourceDetailsFailure: false,
            }
        case GET_RESOURCE_DETAILS_SUCCESS:
            return {
                ...state,
                resourceDetails: action.data,
                getResourceDetailsRequesting: false,
                getResourceDetailsSuccess: true,
                getResourceDetailsFailure: false,
            }
        case GET_RESOURCE_DETAILS_FAILURE:
            return {
                ...state,
                getResourceDetailsRequesting: false,
                getResourceDetailsSuccess: false,
                getResourceDetailsFailure: true,
            }
        case GET_RESOURCE_DETAILS_REQUESTING:
            return {
                ...state,
                getResourceDetailsRequesting: false,
                getResourceDetailsSuccess: false,
                getResourceDetailsFailure: true,
            }
        case UPDATE_RESOURCE_VIEW_COUNT_REQUESTING:
            return {
                ...state,
                updateResourceViewCountRequesting: true,
                updateResourceViewCountSuccess: false,
                updateResourceViewCountFailure: false
            }
        case UPDATE_RESOURCE_VIEW_COUNT_SUCCESS:
            return {
                ...state,
                updateResourceViewCountRequesting: false,
                updateResourceViewCountSuccess: true,
                updateResourceViewCountFailure: false
            }
        case UPDATE_RESOURCE_VIEW_COUNT_FAILURE:
            return {
                ...state,
                updateResourceViewCountRequesting: false,
                updateResourceViewCountSuccess: false,
                updateResourceViewCountFailure: true
            }
        case CLEAR_RESOURCE_DATA:
            return {
                ...state,
                resourceList: []
            }
        case CLEAR_RESOURCE_DETAILS:
            return {
                ...state,
                resourceList: [],
                resourceDetails: {}
            }
        default:
            return state;
    }
}
