import React from "react";
import "../../css/SubmitContainer.css";

import { Button } from "@material-ui/core";

function SubmitContainer() {
  return (
    <div className="submit__container">
      <div>
        <h1>Got a great piece of Founder Content to add?</h1>
        <h3>Tweet at us something like this...</h3>
      </div>
      <div className="submitContainer__tweet">
        {" "}
        <h3>
          Hey <strong>@nptimms</strong> <strong>@dudabardavid</strong>, we love{" "}
          <strong>"this article from this awesome Founder"</strong>. We think
          that it's worth adding to FounderContent.com! 🔥🔥🔥
        </h3>
      </div>
      <a
        className="submitContainer__tweetSource"
        href="https://twitter.com/intent/tweet?url=&text=Hey%20%40nptimms%20%40dudabardavid%2C%20we%20love%20%22this%20article%20from%20this%20awesome%20Founder%22.%20We%20think%20that%20it's%20worth%20adding%20to%20FounderContent.com!%20%F0%9F%94%A5%F0%9F%94%A5%F0%9F%94%A5"
        target="_blank"
      >
        <div className="submitContainer__tweetButton">
          <Button>
            <i className="fab fa-twitter"></i> Tweet your suggestion
          </Button>
        </div>
      </a>
    </div>
  );
}

export default SubmitContainer;
