import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from "redux-devtools-extension";
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'
import thunk from "redux-thunk";

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const middleware = [thunk];

// export default () => {
//     let store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(...middleware)))
//     let persistor = persistStore(store)
//     return { store, persistor }
// }

export default createStore(
    rootReducer,
    {},
    composeWithDevTools(applyMiddleware(...middleware))
);
