import React, { useState } from "react";
import "../../css/Feed.css";
import "../../css/FilterComponent.css";
import { remove } from "lodash";
import { Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";

function FilterComponent(props) {
  const { filterDetails, handleFilter } = props;
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilterObj, setSelectedFilterObj] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleFilter = (selectedFilter, selectedOption) => {
  //     console.log('SelectedFilter', selectedFilter, selectedOption);
  // }

  const handleFilterSelection = (event, name, options) => {
    setAnchorEl(event.currentTarget);
    setMenuOptions(options);
    setSelectedFilter(name);
  };

  const handleOnMenuClick = menuItem => {
    const filterListCopy = [...selectedFilterObj];
    remove(filterListCopy, filter => {
      return filter.filterBy == selectedFilter;
    });
    filterListCopy.push({ filterBy: selectedFilter, filterValue: menuItem });
    setSelectedFilterObj(filterListCopy);
    handleFilter(filterListCopy);
    handleClose();
  };

  const handleFilterClear = () => {
    setSelectedFilterObj([]);
    handleFilter();
  };

  return (
    <div className="feed__filters">
      {filterDetails &&
        filterDetails.map(({ name, displayName, options }) => {
          const selectedFilterIndex = selectedFilterObj.findIndex(
            selectedFilter => selectedFilter.filterBy == name
          );
          return selectedFilterIndex > -1 ? (
            <Button
              variant="outlined"
              name={name}
              onClick={event => handleFilterSelection(event, name, options)}
            >
              {displayName}:{" "}
              {selectedFilterObj[selectedFilterIndex].filterValue}
            </Button>
          ) : (
              <Button
                variant="outlined"
                name={name}
                onClick={event => handleFilterSelection(event, name, options)}
              >
                {displayName}
              </Button>
            );
        })}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuOptions &&
          menuOptions.map(menuItem => {
            return (
              <MenuItem
                onClick={() => {
                  handleOnMenuClick(menuItem);
                }}
              >
                {menuItem}
              </MenuItem>
            );
          })}
      </Menu>
      {selectedFilterObj.length ? (
        <Button variant="outlined" onClick={handleFilterClear}>
          <div className="filterComponent">
            <CloseIcon /> Clear
          </div>
        </Button>
      ) : (
          ""
        )}
    </div>
  );
}

export default FilterComponent;
