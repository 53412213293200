import React, { useEffect, useState, } from "react";
import moment from "moment";
import "../../css/Feed.css";
import db from "../../../firebase";
import { uniq, compact } from "lodash";
import LazyLoad from "react-lazyload";
import FeedOption from "./FeedOption";
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import FilterComponent from "../common/FilterComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { times } from "lodash";

const useStyles = makeStyles(theme => (
  {
    loading: {
      width: "100px",
      height: "100px",
      zIndex: 10000,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: "auto"
    },
    boxStyle: {
      border: "1px solid var(--fc-background)",
      borderRadius: "4px",
      overflow: "hidden",
      marginRight: "10px",
      marginBottom: "20px",
      padding: "10px",
      maxWidth: "488px",
      transition: "transform 100ms ease -in",
    }
  }

));

function Feed(props) {
  const classes = useStyles();
  const {
    page,
    total,
    setPage,
    loading,
    lastPage,
    setLoading,
    handleFilter,
    categoryList,
    handleLoadMore,
    categoryDetails,
  } = props;

  const [filterDetails, setFilterDetails] = useState([
    {
      name: "year",
      displayName: "Year",
      options: [2018, 2019]
    },
    {
      name: "contentType",
      displayName: "Story Type",
      options: ["Podcast"]
    },
    {
      name: "companyFunding",
      displayName: "Funding",
      options: ["VC Backed"]
    },
    {
      name: "companyRevenue",
      displayName: "Revenue Range",
      options: ["$10M - $100M"]
    },
    {
      name: "deckStage",
      displayName: "Deck Stage",
      options: ["Seed", "Series F", "Series D"]
    }
  ]);

  const Loading = (props) => {
    return times(props.number, () => {
      return <Grid item xs={12} sm={6} lg={4} container wrap="nowrap">
        <Box key={props.key} width={310} className={classes.boxStyle}>
          <Skeleton variant="rect" width={285} height={200} />
          <Box pt={0.5}>
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        </Box>
      </Grid>
    })
  }

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 400 >= document.documentElement.offsetHeight) {
      lastPage && lastPage <= page ? setLoading(false) : handleLoadMore();
      return;
    }
  }

  useEffect(async () => {
    let snapshot = await db.collection("resources").get({ source: 'cache', includeMetadataChanges: true })
    if (!snapshot.exists) {
      snapshot = await db.collection("resources").get({ source: 'server', includeMetadataChanges: true })
    }

    const optionObj = {};
    snapshot.docs.map(doc => {
      optionObj.hasOwnProperty("year")
        ? optionObj["year"].push(doc.data().year)
        : (optionObj["year"] = []);
      optionObj.hasOwnProperty("contentType")
        ? optionObj["contentType"].push(doc.data().contenttype)
        : (optionObj["contentType"] = []);
      optionObj.hasOwnProperty("companyFunding")
        ? optionObj["companyFunding"].push(doc.data().companyfunding)
        : (optionObj["companyFunding"] = []);
      optionObj.hasOwnProperty("companyRevenue")
        ? optionObj["companyRevenue"].push(doc.data().companyrevenue)
        : (optionObj["companyRevenue"] = []);
      optionObj.hasOwnProperty("deckStage")
        ? optionObj["deckStage"].push(doc.data().deckstage)
        : (optionObj["deckStage"] = []);
    });

    const filterDetailsClone = [...filterDetails];
    filterDetailsClone.forEach(filter => {
      filter.options = compact(uniq(optionObj[filter.name]).sort());
    });
    setFilterDetails(filterDetailsClone);

  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastPage, page])

  return (
    <div className="feed">
      <div>
        <h1>
          {categoryDetails ?.emoji} {categoryDetails ?.name}{" "}
          {total !== 0 && `(${total})`}
        </h1>
        <h3>Browse the World’s biggest curated list from top Founders.</h3>
        <p>
          {categoryList ?.length != 0 && `${categoryList ?.length}`} results.
          {categoryList && categoryList[0] && categoryList[0].timestamp
            ? ` Last updated ${moment(categoryList[0].timestamp).format(
              "DD MMMM"
            )}.`
            : ""}
        </p>
      </div>
      <FilterComponent
        filterDetails={filterDetails}
        handleFilter={handleFilter}
      />
      <Grid container>
        <div className="feed_feedOptions">
          {
            categoryList &&
            categoryList.length > 0 &&
            categoryList.map(item => (
              <Grid item xs={12} sm={6} lg={4}>
                <FeedOption
                  title={item.title}
                  itemId={item.id}
                  urlTitle={item.urlTitle}
                  tileimage={item.tileimage}
                  contenttype={item.contenttype}
                  yearpublished={item.yearpublished}
                  categoryId={categoryDetails ?.id}
                  categoryName={categoryDetails ?.name}
                />
              </Grid>
            ))}
          {loading ? <Loading number={7} /> : ""}
        </div>
      </Grid>
    </div >
  );
}

export default Feed;
