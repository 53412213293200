import React from "react";
import "../../css/WhyContainer.css";
import dudanick from "../../img/DN.jpg";

import { Button } from "@material-ui/core";

function PrivacyContainer() {
  return (
    <div className="why__container">
      <div>
        <h1>Privacy</h1>
      </div>
      <div className="whyContainer__letter">
        {" "}
        <p>
          Last updated: November 11th 2020. <br />
          <br />
          This Privacy Policy explains FounderContent.com’s privacy practices
          for visitors to our proprietary Website at www.foundercontent.com.{" "}
          <br />
          <br />
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information when you use our Services. We
          will not use or share your information with anyone except as described
          in this Privacy Policy. <br />
          <br />
          By using our Website, you consent to the collection, use, and
          disclosure of your personal information as described in this Privacy
          Policy. Remember that your use of FounderContent.com is at all times
          subject to the Terms of Use, which incorporates this Privacy Policy.{" "}
          <br />
          <br />
          What information does FounderContent.com collect? <br />
          We receive and store any information you knowingly provide to us. For
          example, through the registration process, we will collect your email
          address. <br />
          <br />
          We may communicate with you if you’ve provided us the means to do so.
          For example, if you’ve given us your email address, we may send you
          promotional email offers or email you about your use of the Services.
          If you do not want to receive communications from us, please email us
          at support@dragapp.com. <br />
          <br />
          Cookies & Other Online Identification Tools
          <br />
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          web site and stored on your computer’s hard drive. <br />
          <br />
          We use Cookies to collect information. You can instruct your browser
          to refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Service. <br />
          <br />
          The Website includes Social Media Features, such as the Facebook share
          button. These Features may collect your IP address, which page you are
          visiting on the Website, and may set a cookie to enable the Feature to
          function properly. Social Media Features are hosted by a third party.
          Your interactions with these Features are governed by the privacy
          policy of the company providing it. <br />
          <br />
          Links to other sites
          <br />
          This Privacy Policy applies solely to information collected by our
          Website. Our Service may contain links to other sites that are not
          operated by us. If you click on a third party link, you will be
          directed to that third party’s site. We strongly advise you to review
          the Privacy Policy of every site you visit. <br />
          <br />
          We, therefore, have no responsibility or liability for the content and
          activities of these linked websites. <br />
          <br />
          Children <br />
          We do not knowingly collect information from minors. To use the
          Website, you must be the age of legal majority in your place of
          residence. By using the Website, you hereby represent that you are at
          least the age of legal majority in your place of residence. <br />
          <br />
          We do not use an application or other mechanism to determine the age
          of users of the Website. All information provided to
          FounderContent.com will be treated as if it was provided by an adult.{" "}
          <br />
          <br />
          If, however, we learn that a minor has submitted information about
          himself/herself to us, we delete the information as soon as possible.
          If you believe that a minor may have provided us personal information,
          please contact us at gdpr@dragapp.com Changes to this Privacy Policy
          We may occasionally update our Privacy Policy from time to time. We
          will notify you of any changes by posting the new Privacy Policy on
          this page and revise the “Last update” date at the top of this web
          page. You are advised to review this Privacy Policy periodically for
          any changes. Changes to this Privacy Policy are effective when they
          are posted on this page. Your continued use of the Site or Services
          after the posting of the modified Privacy Policy constitutes your
          agreement to abide and be bound by it. If you object to any
          modification, your sole recourse is to stop using the Site and
          Services. <br />
          <br />
          You can also read everything about our terms. <br />
          <br />
          Contact <br />
          Us If you have any questions about this Privacy Policy, please contact
          us at access@dragapp.com.
        </p>
      </div>
    </div>
  );
}

export default PrivacyContainer;
