import React from "react";
import "../../css/WhyContainer.css";
import dudanick from "../../img/DN.jpg";

import { Button } from "@material-ui/core";

function WhyContainer() {
  return (
    <div className="why__container">
      <div>
        <h1>The big ‘Why’.</h1>
        <h3>
          FC is a community to help with the ‘hard things’. <br />
          It’s for Founders that give a s#*t about other Founders.
        </h3>
      </div>
      <div className="whyContainer__letter">
        {" "}
        <p>
          Dear Founders, <br />
          <br /> Firstly, we get it. Starting a company is hard, painful, and
          relentless. We feel this every day, building our startup,{" "}
          <a href="https://www.dragapp.com">Drag</a>. <br />
          <br />
          We are a married couple (<a href="https://www.nicktimms.com">
            Nick
          </a>{" "}
          and <a href="https://www.dudabardavid.com">Duda</a>) running our
          startup so work often carries into non-work time. Being startup
          Founders (and the challenges it brings in all areas of your life)
          means a lot to us.
          <br />
          <br />
        </p>
        <img className="whyContainer_image" src={dudanick} />
        <p>
          <br />
          We’re on a mission to build a community of Founders that give a s#*t
          about other Founders. We have commited 200 hours to sourcing,
          reviewing and building the World’s biggest curated list of resources
          from world-class founders. <br />
          <br />
          We are committing another 1,000 hours over the next 12 months to
          building out the World’s best app to deliver Founder Content, daily,
          to your inbox. <br />
          <br />
          Here are a few things you can do to support Founder Content: <br />
          <br />
          1. Join <a href="/">FC newsletter</a> <br />
          2. Join{" "}
          <a href="https://www.facebook.com/groups/foundercontent">
            FC Facebook Group
          </a>{" "}
          <br />
          3. Browse FC resources and share your favorite content on Twitter 🙂{" "}
          <br />
          <br />
          All the best and good luck on your own journey. <br />
          <br />
          <strong>Nick and Duda @ Drag.</strong>
        </p>
      </div>
    </div>
  );
}

export default WhyContainer;
