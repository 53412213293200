import React, { useEffect } from "react";
import "../../css/Sidebar.css";
import SearchIcon from "@material-ui/icons/Search";
import FounderSideBarOption from "./FounderSideBarOption";
import ActiveCampaign from "./ActiveCampaign";
import facebook_icon from "../../logo/facebook_icon.png";
import slack_icon from "../../logo/slack_icon.png";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { fetchFounderCategories } from "../../../store/actions/CategoryAction";

function FounderSidebar(props) {

  const { searchText, handleOnChange } = props;
  const dispatch = useDispatch();
  const { founderCategoryList } = useSelector(state => state.categoryReducer)

  useEffect(async () => {
    if (!founderCategoryList || founderCategoryList.length == 0) {
      dispatch(fetchFounderCategories())
    }
  }, [founderCategoryList]);

  return (
    <div className="sidebar">
      <div className="sidebar__input">
        <SearchIcon className="widgets__searchIcon" />
        <input placeholder="Search for Founder Content" value={searchText} type="text" onChange={handleOnChange} />
      </div>
      {founderCategoryList && founderCategoryList.map(category => (
        <FounderSideBarOption
          categoryName={category.name}
          emoji={category.emoji}
          id={category.id}
          path={category.path}
        />
      ))}

      <Button></Button>
      <div className="sidebar__slackCommunity">
        <div className="sidebar__slackLogo">
          <img className="header__logo" src={slack_icon} />
        </div>
        <div>
          <a
            className="sidebar__button"
            href="https://join.slack.com/t/foundercontent/shared_invite/zt-j2qgppsm-sHN6kB~9lEjW0rF2OoGS8g"
          >
            Join <strong> Slack Community</strong>
          </a>
        </div>
      </div>
      <div className="sidebar__facebookCommunity">
        <div className="sidebar__facebookLogo">
          <img className="header__logo" src={facebook_icon} />
        </div>
        <div>
          <a
            className="sidebar__button"
            href="https://www.facebook.com/groups/foundercontent"
          >
            Join <strong> Facebook Community</strong>
          </a>
        </div>
      </div>

      <div className="sidebar__subscription">
        <h2>Are you a Founder?</h2>
        <p>The best new Founder Content straight to your inbox 👇</p>
      </div>
      <ActiveCampaign isFormSubmit={false} />
    </div>
  );
}

export default FounderSidebar;
