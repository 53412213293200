import React, { useEffect, useState } from "react";
import "../../css/Feed.css";
import db from "../../../firebase";
import { uniq, compact } from "lodash"
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import FounderFeedOption from "./FounderFeedOption";
import FilterComponent from "../common/FilterComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => (
  {
    loading: {
      width: "100px",
      height: "100px",
      zIndex: 10000,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: "auto"
    },
  }
));

function FounderFeed(props) {
  const classes = useStyles();
  const { handleFilter, founderList, founderCategoryDetails, loading } = props;
  const [filterDetails, setFilterDetails] = useState([
    {
      name: 'founderCountry',
      displayName: 'Location',
      options: ['United States', 'United Kingdom']
    },
    {
      name: 'founderGender',
      displayName: 'Gender',
      options: ["Male", "Female"]
    },
    {
      name: 'jobRole',
      displayName: 'Role',
      options: ["CEO"]
    }
  ]);

  useEffect(async () => {
    let snapshot = await db.collection("founders").get({ source: 'cache', includeMetadataChanges: true });

    if (!snapshot.exists) {
      snapshot = await db.collection("founders").get({ source: 'server', includeMetadataChanges: true });
    }

    const optionObj = {}
    snapshot.docs.map(doc => {
      optionObj.hasOwnProperty('founderCountry') ? optionObj['founderCountry'].push(doc.data().foundercountry) : optionObj['founderCountry'] = [];
      optionObj.hasOwnProperty('founderGender') ? optionObj['founderGender'].push(doc.data().foundergender) : optionObj['founderGender'] = [];
      optionObj.hasOwnProperty('jobRole') ? optionObj['jobRole'].push(doc.data().jobrole) : optionObj['jobRole'] = [];
    })

    const filterDetailsClone = [...filterDetails];
    filterDetailsClone.forEach((filter) => {
      filter.options = compact(uniq(optionObj[filter.name]))
    })
    setFilterDetails(filterDetailsClone);

  }, [])


  return (
    <div className="feed">
      <div>
        <h1>
          {founderCategoryDetails ?.emoji} {founderCategoryDetails ?.name}{" "}
          {founderList.length != 0 && `(${founderList.length})`}
        </h1>
        <h3>Browse the World’s biggest curated list from top Founders.</h3>
        <p>
          {founderList.length != 0 && `${founderList.length}`}{" "}
          results ·
        </p>
      </div>
      <FilterComponent filterDetails={filterDetails} handleFilter={handleFilter} />
      <Grid container>
        {loading ? <CircularProgress color="inherit" size={20} className={classes.loading} /> :
          <div className="feed_feedOptions" style={loading === true ? { opacity: 0.3 } : {}}>
            {founderList &&
              founderList.map(item => (
                <Grid item xs={12} sm={6} lg={4}>
                  <FounderFeedOption
                    firstname={item.firstname}
                    lastname={item.lastname}
                    company={item.company}
                    jobrole={item.jobrole}
                    founderimage={item.founderimage}
                    foundercategoryid={item.foundercategoryid}
                    id={item.id}
                    founderCategoryName={
                      founderCategoryDetails && founderCategoryDetails.name
                    }
                  // title={item.title}
                  // categoryId={id}
                  // itemId={item.id}
                  // tileimage={item.tileimage}
                  // contenttype={item.contenttype}
                  // yearpublished={item.yearpublished}
                  />
                </Grid>
              ))}
          </div>}
      </Grid>
    </div>
  );
}

export default FounderFeed;
