import {
    FETCH_RESOURCES_LIST_REQUESTING,
    FETCH_RESOURCES_LIST_SUCCESS,
    FETCH_RESOURCES_LIST_FAILURE,
    FETCH_FOUNDER_ARTICLES_REQUESTING,
    FETCH_FOUNDER_ARTICLES_SUCCESS,
    FETCH_FOUNDER_ARTICLES_FAILURE,
    GET_RESOURCE_DETAILS_REQUESTING,
    GET_RESOURCE_DETAILS_SUCCESS,
    GET_RESOURCE_DETAILS_FAILURE,
    UPDATE_RESOURCE_VIEW_COUNT_SUCCESS,
    UPDATE_RESOURCE_VIEW_COUNT_FAILURE,
    UPDATE_RESOURCE_VIEW_COUNT_REQUESTING,
    FETCH_PAGINATED_LIST_REQUESTING,
} from "../constants/ActionTypes";
import {
    FetchResourceAPI,
    SearchResourceAPI,
    FilterResourceAPI,
    GetResourceDetailsAPI,
    GetResourceByTitleAPI,
    GetResourceBySearchTitleAPI,
    FetchResourceByCategoryAPI,
    UpdateResourceViewCountAPI,
    FetchResourceByFounderIdAPI
} from "../../services/ResourceService";

export const fetchAllResource = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_PAGINATED_LIST_REQUESTING });
        let apiResponse = await FetchResourceAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_RESOURCES_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const list = apiResponse.response.data.map((resource) => formatResourceList(resource));
            dispatch({
                type: FETCH_RESOURCES_LIST_SUCCESS,
                data: {
                    list, page: apiResponse.response.page,
                    total: apiResponse.response.total,
                    lastPage: apiResponse.response.lastPage,
                    isPagination: requestParameter && requestParameter.pageNumber ? true : false
                }
            });
        } else {
            dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
        }
    } catch (error) {
        console.log('err ->', error);
        dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
    }
};


export const fetchResourceByCategoryId = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_RESOURCES_LIST_REQUESTING });
        let apiResponse = await FetchResourceByCategoryAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_RESOURCES_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse) {
            const data = apiResponse.response.data.map((resource) => formatResourceList(resource));
            dispatch({ type: FETCH_RESOURCES_LIST_SUCCESS, data: { list: data, total: apiResponse.response.total, isPagination: requestParameter.pageNumber ? true : false } });
        } else {
            dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
    }
};

export const searchResource = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_PAGINATED_LIST_REQUESTING });
        let apiResponse = await SearchResourceAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_RESOURCES_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse) {
            const data = apiResponse.response.data.map((resource) => formatResourceList(resource));
            dispatch({
                type: FETCH_RESOURCES_LIST_SUCCESS, data: {
                    list: data, total: data.length,
                    total: apiResponse.response.total,
                    page: apiResponse.response.page,
                    lastPage: apiResponse.response.lastPage,
                    isPagination: requestParameter.pageNumber && requestParameter.pageNumber > 0 ? true : false,
                }
            });
        } else {
            dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
    }
};

export const filterResource = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_RESOURCES_LIST_REQUESTING });
        let apiResponse = await FilterResourceAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_RESOURCES_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse) {
            const data = apiResponse.response.data.map((resource) => formatResourceList(resource));
            dispatch({ type: FETCH_RESOURCES_LIST_SUCCESS, data: { list: data, total: apiResponse.response.total, isPagination: requestParameter.pageNumber ? true : false } });
        } else {
            dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
    }
};

export const fetchFounderArticles = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_FOUNDER_ARTICLES_REQUESTING });
        const apiResponse = await FetchResourceByFounderIdAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_FOUNDER_ARTICLES_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = apiResponse.response.data.map((resource) => formatResourceList(resource));
            dispatch({ type: FETCH_FOUNDER_ARTICLES_SUCCESS, data });
        } else {
            dispatch({ type: FETCH_FOUNDER_ARTICLES_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_FOUNDER_ARTICLES_FAILURE });
    }
};

export const getResourceByArticle = requestParameter => async dispatch => {
    try {
        dispatch({ type: GET_RESOURCE_DETAILS_REQUESTING });
        let apiResponse = await GetResourceByTitleAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: GET_RESOURCE_DETAILS_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response && apiResponse.response.length) {
            const data = formatResourceList(apiResponse.response[0]);
            dispatch({ type: GET_RESOURCE_DETAILS_SUCCESS, data });
        } else {
            dispatch({ type: GET_RESOURCE_DETAILS_FAILURE });
        }
    } catch (error) {
        dispatch({ type: GET_RESOURCE_DETAILS_FAILURE });
    }
};

export const getResourceBySearchTitle = requestParameter => async dispatch => {
    try {
        dispatch({ type: GET_RESOURCE_DETAILS_REQUESTING });
        let apiResponse = await GetResourceBySearchTitleAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: GET_RESOURCE_DETAILS_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response && apiResponse.response.data.length) {
            const data = formatResourceList(apiResponse.response.data[0]);
            dispatch({ type: GET_RESOURCE_DETAILS_SUCCESS, data });
        } else {
            dispatch({ type: GET_RESOURCE_DETAILS_FAILURE });
        }
    } catch (error) {
        dispatch({ type: GET_RESOURCE_DETAILS_FAILURE });
    }
};

export const getResourceDetails = requestParameter => async dispatch => {
    try {
        dispatch({ type: GET_RESOURCE_DETAILS_REQUESTING });
        let apiResponse = await GetResourceDetailsAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: GET_RESOURCE_DETAILS_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = formatResourceList(apiResponse.response);
            dispatch({ type: GET_RESOURCE_DETAILS_SUCCESS, data });
        } else {
            dispatch({ type: GET_RESOURCE_DETAILS_FAILURE });
        }
    } catch (error) {
        dispatch({ type: GET_RESOURCE_DETAILS_FAILURE });
    }
};

export const updateResourceViewCount = requestParameter => async dispatch => {
    try {
        dispatch({ type: UPDATE_RESOURCE_VIEW_COUNT_REQUESTING });
        let apiResponse = await UpdateResourceViewCountAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: UPDATE_RESOURCE_VIEW_COUNT_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = formatResourceList(apiResponse.response);
            dispatch({ type: UPDATE_RESOURCE_VIEW_COUNT_SUCCESS, data });
        } else {
            dispatch({ type: UPDATE_RESOURCE_VIEW_COUNT_FAILURE });
        }
    } catch (error) {
        dispatch({ type: UPDATE_RESOURCE_VIEW_COUNT_FAILURE });
    }
};

export const getResourceByFounder = requestParameter => async dispatch => {
    try {
        dispatch({ type: FETCH_RESOURCES_LIST_REQUESTING });
        let apiResponse = await FetchResourceByFounderIdAPI(requestParameter);
        if (!apiResponse) {
            return dispatch({ type: FETCH_RESOURCES_LIST_FAILURE, message: "Incorrect Details", errors: "Something went wrong..!!" });
        }
        if (apiResponse && apiResponse.response) {
            const data = apiResponse.response.data.map((articles) => formatResourceList(articles))
            dispatch({ type: FETCH_RESOURCES_LIST_SUCCESS, data: { list: data, total: apiResponse.response.total, isPagination: requestParameter.pageNumber ? true : false } });
        } else {
            dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
        }
    } catch (error) {
        dispatch({ type: FETCH_RESOURCES_LIST_FAILURE });
    }
};

const formatResourceList = (resource) => {
    return {
        id: resource.id,
        categoryid: resource ?.category ?.id ?.id,
        categoryname: resource ?.category ?.id ?.name,
        founderid: resource ?.founder ?.id,
        company: resource.company,
        deckstage: resource.deckStage,
        deckamountraised: resource.deckAmountRaised,
        companyrevenue: resource.companyRevenue,
        companyfunding: resource.companyFunding,
        title: resource.title,
        urlTitle: resource.urlTitle,
        originalsource: resource.originalSource,
        freatureimage: resource.featuredImage,
        createdat: resource.createdAt,
        updatedat: resource.updatedAt,
        viewcount: resource.viewCount,
        tileimage: resource.titleImage,
        contenttype: resource.contentType,
        yearpublished: resource.year,
        timestamp: resource.timestamp
    }
}

