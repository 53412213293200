import React from "react";
import { Link } from "react-router-dom";

import "../../css/SignupContainer.css";
import founderfeed from "../../img/new-template-home.png";
import logo from "../../logo/FC-large.png";
import { Button } from "@material-ui/core";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterTweetEmbed,
  Tweet
} from "react-twitter-embed";
import "../../css/HotContainer.css";

function HotContainer() {
  return (
    <div className="submit__container">
      <div>
        <h1>Here's what's 🔥 this week</h1>
        <h3>Add #FounderContent to your Tweet to be added</h3>
      </div>

      <div className="hotContainer__tweetEmbed">
        {" "}
        {/* <TwitterTweetEmbed tweetId={"1327529862177972224"} /> */}
        {/* <TwitterTimelineEmbed
          sourceType="profile"
          screenName="nptimms"
          options={{ width: 600 }}
        /> */}
        <TwitterTweetEmbed
          tweetId={"1340654900955521026"}
          options={{ width: 800 }}
        />{" "}
        {/* <TwitterShareButton url={"https://www.twitter.com/nptimms"} /> */}
      </div>
    </div>
  );
}

export default HotContainer;
