import {
    FETCH_RESOURCE_CATEGORY_REQUESTING,
    FETCH_RESOURCE_CATEGORY_SUCCESS,
    FETCH_RESOURCE_CATEGORY_FAILURE,
    FETCH_FOUNDER_CATEGORY_REQUESTING,
    FETCH_FOUNDER_CATEGORY_SUCCESS,
    FETCH_FOUNDER_CATEGORY_FAILURE,
    FETCH_RESOURCE_CATEGORY_DETAILS_REQUESTING,
    FETCH_RESOURCE_CATEGORY_DETAILS_SUCCESS,
    FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE,
    GET_FOUNDER_CATEGORY_DETAILS_REQUESTING,
    GET_FOUNDER_CATEGORY_DETAILS_SUCCESS,
    GET_FOUNDER_CATEGORY_DETAILS_FAILURE
} from "../constants/ActionTypes";

const initialState = {
    fetchResourceCategoryRequesting: false,
    fetchResourceCategorySuccess: false,
    fetchResourceCategoryFailure: false,
    resourceCategoryList: [],
    fetchResourceCategoryRequesting: false,
    fetchResourceCategorySuccess: false,
    fetchResourceCategoryFailure: false,
    founderCategoryList: [],
    fetchResourceCategoryDetailsRequesting: false,
    fetchResourceCategoryDetailsSuccess: false,
    fetchResourceCategoryDetailsFailure: false,
    resourceCategoryDetails: {},
};

export default function CategoryReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_RESOURCE_CATEGORY_REQUESTING:
            return {
                ...state,
                fetchResourceCategoryRequesting: true,
                fetchResourceCategorySuccess: false,
                fetchResourceCategoryFailure: false,
            };
        case FETCH_RESOURCE_CATEGORY_SUCCESS:
            return {
                ...state,
                fetchResourceCategoryRequesting: false,
                fetchResourceCategorySuccess: true,
                fetchResourceCategoryFailure: false,
                resourceCategoryList: action.data
            };
        case FETCH_RESOURCE_CATEGORY_FAILURE:
            return {
                ...state,
                fetchResourceCategoryRequesting: false,
                fetchResourceCategorySuccess: false,
                fetchResourceCategoryFailure: true,
            };
        case FETCH_FOUNDER_CATEGORY_REQUESTING:
            return {
                ...state,
                fetchResourceCategoryRequesting: true,
                fetchResourceCategorySuccess: false,
                fetchResourceCategoryFailure: false
            };
        case FETCH_FOUNDER_CATEGORY_SUCCESS:
            return {
                ...state,
                fetchResourceCategoryRequesting: false,
                fetchResourceCategorySuccess: true,
                fetchResourceCategoryFailure: false,
                founderCategoryList: action.data
            };
        case FETCH_FOUNDER_CATEGORY_FAILURE:
            return {
                ...state,
                fetchResourceCategoryRequesting: false,
                fetchResourceCategorySuccess: false,
                fetchResourceCategoryFailure: true
            };
        case FETCH_RESOURCE_CATEGORY_DETAILS_REQUESTING:
            return {
                ...state,
                fetchResourceCategoryDetailsRequesting: true,
                fetchResourceCategoryDetailsSuccess: false,
                fetchResourceCategoryDetailsFailure: false,
            }
        case FETCH_RESOURCE_CATEGORY_DETAILS_SUCCESS:
            return {
                ...state,
                fetchResourceCategoryDetailsRequesting: false,
                fetchResourceCategoryDetailsSuccess: true,
                fetchResourceCategoryDetailsFailure: false,
                resourceCategoryDetails: action.data
            }
        case FETCH_RESOURCE_CATEGORY_DETAILS_FAILURE:
            return {
                ...state,
                fetchResourceCategoryDetailsRequesting: false,
                fetchResourceCategoryDetailsSuccess: false,
                fetchResourceCategoryDetailsFailure: true,
            }
        case GET_FOUNDER_CATEGORY_DETAILS_REQUESTING:
            return {
                ...state,
                getFounderCategoryDetailsRequesting: true,
                getFounderCategoryDetailsSuccess: false,
                getFounderCategoryDetailsFailure: false,
            }
        case GET_FOUNDER_CATEGORY_DETAILS_SUCCESS:
            return {
                ...state,
                getFounderCategoryDetailsRequesting: false,
                getFounderCategoryDetailsSuccess: true,
                getFounderCategoryDetailsFailure: false,
                categoryDetails: action.data
            }
        case GET_FOUNDER_CATEGORY_DETAILS_REQUESTING:
            return {
                ...state,
                getFounderCategoryDetailsRequesting: false,
                getFounderCategoryDetailsSuccess: false,
                getFounderCategoryDetailsFailure: true,
            }
        default:
            return state;
    }
}
