import React from "react";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import drag from "../../logo/logo-dark-new.png";

import "../../css/Footer.css";

function Footer() {
  return (
    <Grid container className="footer">
      <Grid container style={{ "justify-content": "center" }}>
        <Grid item>
          <Link className="header__link" to="/why">
            <Button className="button__primary">Why</Button>
          </Link>
        </Grid>
        <Grid item>
          <Link className="header__link" to="/resources">
            <Button className="button__primary">Resources</Button>
          </Link>
        </Grid>
        <Grid item>
          <Link className="header__link" to="/founders/all">
            <Button className="button__primary">Founders</Button>
          </Link>
        </Grid>
        <Grid item>
          <Link className="header__link" to="/hot">
            <Button className="button__primary">What's hot</Button>
          </Link>
        </Grid>
        <Grid item>
          <Link className="header__link" to="/add">
            <Button className="button__primary">Add</Button>
          </Link>
        </Grid>
        <Grid item>
          <Link className="header__link" to="/">
            <Button className="button__primary">Join free</Button>
          </Link>
        </Grid>
      </Grid>
      <div className="footer__logo">
        {" "}
        <img className="footer__imgLogo" src={drag} />
      </div>
      <div className="footer__text">
        <p>Founder Content was created by Drag. </p>
        <Link className="header__link" to="/why">
          <p>
            <strong> Read Why here.</strong>
          </p>
        </Link>
      </div>
      <div className="footer__base">
        <Link className="header__link" to="/terms">
          <span>
            <strong> Terms</strong>
          </span>
        </Link>
        <Link className="header__link" to="/privacy">
          <span>
            <strong> Privacy</strong>
          </span>
        </Link>
        © 2020 Founder Content. All rights reserved.
      </div>
    </Grid >
  );
}

export default Footer;
