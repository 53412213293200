import React, { useEffect, useState, Fragment, useRef } from "react";
import Feed from "../main/Feed";
import Header from "./Header";
import Fotter from "./Footer";
import _ from "lodash";
import Sidebar from "../main/Sidebar";
import "../../css/HomeContainer.css";
import { useParams } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import { useSelector, useDispatch } from "react-redux";
import { CLEAR_RESOURCE_DATA } from "../../../store/constants/ActionTypes"
import { fetchResourceCategoryDetails } from "../../../store/actions/CategoryAction";
import { fetchAllResource, fetchResourceByCategoryId, searchResource, filterResource } from "../../../store/actions/ResourceAction";

function HomeContainer() {
  const params = useParams();

  const [id, _setId] = useState(params.id);
  const idRef = useRef(id);
  const setId = data => {
    idRef.current = data;
    _setId(data);
  };

  const [searchText, _setSearchText] = useState(null);
  const searchTextRef = useRef(searchText);
  const setSearchText = data => {
    searchTextRef.current = data;
    _setSearchText(data)
  }

  const [filterObj, _setFilterObj] = useState({})
  const filterObjRef = useRef({});
  const setFilterObj = data => {
    filterObjRef.current = data;
    _setFilterObj(data);
  };


  const dispatch = useDispatch();
  const {
    page,
    total,
    lastPage,
    resourceList,
    fetchResourceListRequesting,
    fetchPaginatedListRequesting
  } = useSelector(state => state.resourceReducer);

  const [categoryDetails, setCategoryDetails] = useState({ id: "60242926353f78387818b436", emoji: "🌎", name: "All", path: "all" });
  const [loading, setLoading] = useState(false);
  const { resourceCategoryDetails } = useSelector(state => state.categoryReducer);

  const handleOnChange = event => {
    setSearchText(event.target.value);
  };

  const handleFilter = async (filterObj) => {
    if (_.isEmpty(filterObj)) {
      dispatch(fetchAllResource());
      setFilterObj({})
    } else {
      setFilterObj(filterObj)
      dispatch(filterResource({ filterObj }))
    }
  };

  const handleLoadMore = async () => {
    if (idRef && idRef.current == "all" && (searchTextRef.current == null || searchTextRef.current.trim() == "") && _.isEmpty(filterObjRef.current)) {
      setLoading(true);
      dispatch(fetchAllResource({ pageNumber: page + 1 }));
    } else if (searchTextRef && searchTextRef.current && _.isEmpty(filterObjRef.current)) {
      setLoading(true);
      dispatch(searchResource({ searchText: searchTextRef.current, pageNumber: page + 1 }));
    }
  }

  useEffect(() => {
    if (resourceCategoryDetails && resourceCategoryDetails.id) {
      dispatch(fetchResourceByCategoryId({ categoryId: resourceCategoryDetails.id }))
      setCategoryDetails(resourceCategoryDetails);
    }
  }, [resourceCategoryDetails]);

  useEffect(() => {
    if (idRef.current) {
      if (idRef.current == "all") {
        dispatch(fetchAllResource());
        setCategoryDetails({ id: "60242926353f78387818b436", emoji: "🌎", name: "All", path: "all" })
      } else {
        dispatch(fetchResourceCategoryDetails({ path: idRef.current }));
      }
    }
  }, [idRef.current]);


  useEffect(() => {
    if (!idRef && searchText == null || !idRef && searchText.trim() == "") {
      dispatch(fetchAllResource());
    } else {
      dispatch(searchResource({ searchText }));
    }
  }, [searchText]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_RESOURCE_DATA })
    }
  }, [])

  useEffect(() => {
    setId(params.id)
  }, [params])

  useEffect(() => {
    setLoading(fetchResourceListRequesting ? fetchResourceListRequesting : fetchPaginatedListRequesting)
  }, [fetchResourceListRequesting, fetchPaginatedListRequesting])

  return (
    <Fragment>
      <Header />
      <div className="homeContainer">
        <Hidden smDown>
          <Sidebar handleOnChange={handleOnChange} />
        </Hidden>
        <Feed
          page={page}
          total={total}
          loading={loading}
          lastPage={lastPage}
          setLoading={setLoading}
          searchText={searchText}
          handleFilter={handleFilter}
          categoryList={resourceList}
          id={categoryDetails ?.id}
          handleLoadMore={handleLoadMore}
          categoryDetails={categoryDetails}
        />
      </div>
      <Fotter />
    </Fragment>
  );
}

export default HomeContainer;
