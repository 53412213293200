import axios from "axios";
// import apiErrorHandler from "./apiErrorHandler";

//const SERVER_URL = "http://localhost:9000/api/";
const SERVER_URL = "https://founder-content.herokuapp.com/api/";

let cancelToken = axios.CancelToken;
let source = cancelToken.source();

export const axiosRequestHandler = async (url, method, body, type = null, isFileUpload = false) => {
    try {
        if (type === "CANCEL_API_REQUEST") {
            source.cancel("Operation canceled by the user.");
            let CancelToken = axios.CancelToken;
            source = CancelToken.source();
        }
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        headers['Content-Type'] = isFileUpload ? "multipart/form-data;" : "application/json"

        const accessToken = localStorage.getItem("accessToken");
        if (accessToken && accessToken !== "undefined") {
            headers["Authorization"] = `Bearer ${accessToken}`;
        }
        const requestObj = {
            timeout: 20000,
            url: `${SERVER_URL}${url}`,
            data: {},
            headers: headers,
            method: method,
            params: {},
            cancelToken: source.token
        };

        if (method == "put" || method == "post") {
            requestObj["data"] = body;
        }

        if (method == "get") {
            requestObj["params"] = body;
        }

        const result = await axios(requestObj);

        return { error: null, response: result.data };
    } catch (error) {
        // const apiError = apiErrorHandler(error);
        // return apiError;
    }
};
