import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import drag from "../../logo/logo-dark-new.png";

import "../../css/FooterMini.css";

function Footer() {
  return (
    <div className="footerMini">
      <div className="footer__text">
        <p>Founder Content was created by Drag. </p>
        <Link className="header__link" to="/why">
          <p>
            <strong> Read Why here.</strong>
          </p>
        </Link>
      </div>
      <div className="footer__base">
        © 2020 Founder Content. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;
